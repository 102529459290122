<template>
  <div class="home">
    <help-content />
    <v-footer></v-footer>
  </div>
</template>

<script>
import VFooter from "@/views/components/Footer";
import HelpContent from "@/views/components/HelpContent";

export default {
  components: {VFooter, HelpContent},
  metaInfo:{
    title: 'Yardım',
    meta: [
      { property: 'og:title', content:'Yardım - Online Adam Asmaca Oyunu' },
      { property: 'description', content:"Adam Asmaca oynarken bir sorunla mı karşılaştın, kelimede bir hata olduğunu mu düşünüyorsun, ödülün eline mi ulaşmadı? Bizimle bu sayfadan iletişime geçebilirsin, seninle resmi tatil günleri ve haftasonları hariç 24 saat içerisinde iletişime geçeceğiz!" },
    ]
  },
}
</script>